import { Typography, Box } from "@mui/material";
import "./style.css";
import Buttons from "../../common/button";
import { Controller, useForm } from "react-hook-form";
import { RegExpressions } from "../../../constants/regExp";
import { Input, PasswordInput } from "../../common/input";
import { useRegisterUserMutation } from "../../../redux/services/loginApiSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { Loader } from "../../common/loader";
import { handleErrors } from "../../../utils";

const SignUp = (props: any) => {
  const { signUp, setSignUp } = props;
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      companyName: "",
      confirmPassword: "",
      phone: "",
      fullName: "",
    },
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [registerUser, registerUserInfo] = useRegisterUserMutation<any>();
  const onSubmit = (data: any) => {
    const payload = {
      email: data?.email,
      password: data?.password,
      companyName: data?.companyName,
      phone: data?.phone,
      name: data?.fullName,
    };

    registerUser(payload);
    setEmail(data?.email);
  };
  useEffect(() => {
    if (registerUserInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "OTP sent successfully",
        })
      );
      navigate(Page.OTP, { state: { email: email } });
    }
  }, [registerUserInfo?.isSuccess]);
  useEffect(() => {
    if (registerUserInfo?.isError) {
      handleErrors(registerUserInfo, dispatch);
    }
  }, [registerUserInfo?.isError]);

  return (
    <>
      <Loader loader={registerUserInfo?.isLoading} />
      <Box
        sx={{
          borderRadius: "24px",
          padding: "47px 39px",
          textAlign: "center",
          boxShadow: "0px 4px 29px 0px #00000040",
        }}
      >
        <Typography className="signIn">Sign Up</Typography>
        {/* Sign-In Form */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            render={({
              field: { ref, ...field },
              fieldState: { invalid, error },
            }) => (
              <>
                <Input
                  {...field}
                  label="Full Name"
                  //   placeholder={"Email id"}
                  fullWidth
                  required={true}
                  helperText={error?.message ? error?.message : " "}
                  value={field.value}
                  error={Boolean(error)}
                  onChange={field.onChange}
                />
              </>
            )}
            name="fullName"
            control={control}
            rules={{
              required: "Please enter your full name",
            }}
          />
          <Controller
            render={({
              field: { ref, ...field },
              fieldState: { invalid, error },
            }) => (
              <>
                <Input
                  {...field}
                  label="Email Id"
                  //   placeholder={"Email id"}
                  fullWidth
                  required={true}
                  helperText={error?.message ? error?.message : " "}
                  value={field.value}
                  error={Boolean(error)}
                  onChange={field.onChange}
                />
              </>
            )}
            name="email"
            control={control}
            rules={{
              required: "Please enter your email Id",
              pattern: {
                value: RegExpressions?.Email,
                message: "Please enter valid email",
              },
            }}
          />
          <Controller
            render={({
              field: { ref, ...field },
              fieldState: { invalid, error },
            }) => (
              <>
                <Input
                  {...field}
                  label="Phone"
                  //   placeholder={"Email id"}
                  fullWidth
                  //   required={true}
                  helperText={error?.message ? error?.message : " "}
                  value={field.value}
                  error={Boolean(error)}
                  onChange={field.onChange}
                />
              </>
            )}
            name="phone"
            control={control}
            rules={{
              pattern: {
                value: RegExpressions?.MobileNumber,
                message: "Please enter valid number",
              },
            }}
          />

          <Controller
            render={({
              field: { ref, ...field },
              fieldState: { invalid, error },
            }) => (
              <>
                <PasswordInput
                  {...field}
                  label="Password"
                  //   placeholder={"Password"}
                  value={field.value}
                  error={Boolean(error)}
                  required={true}
                  onChange={field.onChange}
                  helperText={error?.message ? error?.message : ""}
                  variant="standard"
                  fullWidth
                />
              </>
            )}
            name="password"
            control={control}
            rules={{
              required: "Please enter your password",
            }}
          />
          <Controller
            render={({
              field: { ref, ...field },
              fieldState: { invalid, error },
            }) => (
              <>
                <PasswordInput
                  {...field}
                  label="Confirm Password"
                  //   placeholder={"Password"}
                  value={field.value}
                  error={Boolean(error)}
                  required={true}
                  onChange={field.onChange}
                  helperText={error?.message ? error?.message : ""}
                  variant="standard"
                  fullWidth
                />
              </>
            )}
            name="confirmPassword"
            control={control}
            rules={{
              required: "Please enter your password",
              validate: (value) =>
                value === watch("password") || "Passwords do not match", // Validation logic
            }}
          />
          <Controller
            render={({
              field: { ref, ...field },
              fieldState: { invalid, error },
            }) => (
              <>
                <Input
                  {...field}
                  label="Company/Organization Name"
                  //   placeholder={"Email id"}
                  fullWidth
                  required={true}
                  helperText={error?.message ? error?.message : " "}
                  value={field.value}
                  error={Boolean(error)}
                  onChange={field.onChange}
                />
              </>
            )}
            name="companyName"
            control={control}
            rules={{
              required: "Please enter your company name",
            }}
          />
          <Buttons
            variant="contained"
            type="primary"
            sx={{
              marginTop: "20px",
            }}
            text={"Sign Up"}
            buttonType={"submit"}
          ></Buttons>
        </form>
        {/* <Divider>OR</Divider>
                <Button
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                  sx={{ color: "gray", borderColor: "gray" }}
                  fullWidth
                >
                  Sign in with Google
                </Button> */}
        <Typography
          variant="body2"
          sx={{ marginTop: "1rem", color: "#696969" }}
        >
          Already Registered?{" "}
          <span
            onClick={() => setSignUp(false)}
            style={{ color: "#2200FF", cursor: "pointer" }}
          >
            Sign In
          </span>
        </Typography>
      </Box>
    </>
  );
};

export default SignUp;
