import { useEffect, useState } from "react";
import "./style.css";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";

export const MessageAlert = (props: any) => {
  const dispatch = useDispatch();
  const { open, message, alertType } = useSelector(
    (state: any) => state?.alert
  );
  const [alertValue, setAlertValue] = useState<any>("success");

  const handleClose = () => {
    dispatch(setAlert({ open: false, alertType: "", message: "" }));
  };

  useEffect(() => {
    if (alertType) {
      setAlertValue(alertType);
    }
  }, [alertType]);

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          className="alertClass"
          onClose={() => handleClose()}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
