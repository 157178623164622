import { Card, Chip, Popover, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./style.css";
import Buttons from "../../common/button";
import { SEARCHBAR } from "../../common/search";
import { SelectInput } from "../../common/selectInput";
import { Table } from "../../common/table";
import { diamond, ellipsis } from "../../../assets/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import {
  useChangeBotStatusMutation,
  useDeleteBotMutation,
  useGetAllBotsQuery,
} from "../../../redux/services/botApiSlice";
import dayjs from "dayjs";
import { Loader } from "../../common/loader";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { GridPaginationModel } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";

export const Bots = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);
  const [tableHeight, setTableHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [rowData, setRowData] = useState([]);
  const [selectedBotId, setSelectedBotId] = useState(null);
  const [selectedBot, setSelectedBot] = useState();
  const [status, setStatus] = useState("enable");
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? `popover-${selectedBotId}` : undefined;

  const filterOptionValues = [
    { text: "All", disabled: false, value: "all" },
    { text: "Enable", disabled: false, value: "enable" },
    { text: "Disable", disabled: false, value: "disable" },
    // { "text": "Deleted Bots",
    //   "disabled": false,
    //   "value": "delete"
    // }
  ];

  const queryParams = {
    status: status,
    searchTerm: search,
    page: pagination?.page + 1,
    // limit: paginationModel?.pageSize,
  };
  const {
    data,
    isSuccess,
    refetch,
    isLoading,
    fulfilledTimeStamp,
    isFetching,
  }: any = useGetAllBotsQuery(queryParams);
  const [deleteBot, deleteBotInfo] = useDeleteBotMutation();
  const [changeStatus, changeStatusInfo] = useChangeBotStatusMutation();

  const handleBotFilterChange = (filter: any) => {
    setStatus(filter.target.value);
  };

  dayjs.extend(customParseFormat);
  const calHeaight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".botTable");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 100);
    setTableHeight(tableHeight);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick =
    (botId: any, row: any) => (event: React.MouseEvent<HTMLImageElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setSelectedBotId(botId);
      setSelectedBot(row);
    };
  useEffect(() => {
    calHeaight();
    const resizeObserver = new ResizeObserver(() => {
      calHeaight();
      setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  const handleDelete = (row: any) => {
    deleteBot({ id: row?.id });
    setAnchorEl(null);
  };
  useEffect(() => {
    if (deleteBotInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Chatbot deleted successfully",
        })
      );
      refetch();
    }
  }, [deleteBotInfo?.isSuccess]);
  useEffect(() => {
    if (deleteBotInfo?.isError) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: "Error in chatbot deletion",
        })
      );
      refetch();
    }
  }, [deleteBotInfo?.isError]);
  const handleStatus = (row: any) => {
    changeStatus({
      chatbot_id: selectedBotId,
      status: row?.status === "enable" ? "disable" : "enable",
    });
    setAnchorEl(null);
  };
  useEffect(() => {
    if (changeStatusInfo?.isSuccess) {
      refetch();
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Status change successfully",
        })
      );
    }
  }, [changeStatusInfo?.isSuccess]);
  useEffect(() => {
    if (changeStatusInfo?.isError) {
      refetch();
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: "Error in status change",
        })
      );
    }
  }, [changeStatusInfo?.isError]);
  const columns: any = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <p className="slice flex">
              <img src={diamond}></img>
              {params?.value}
            </p>
          </>
        );
      },
    },
    {
      field: "date_created",
      headerName: "Date Created",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => {
        return (
          <>
            <p className="slice">
              {dayjs(params?.value, "DD-MM-YYYY HH:mm:ss").format(
                "MMM DD, YYYY"
              )}
            </p>
          </>
        );
      },
    },
    // {
    //   field: "amount",
    //   headerName: "Amount",
    //   minWidth: 150,
    //   headerAlign: "left",
    //   cellClassName: "cell-padding",
    //   align: "left",
    //   sortable: false,
    //   flex: 2,
    //   // renderCell: (params: any) => {
    //   //   return (
    //   //     <>
    //   //       <p className="slice">{params?.row?.amount}</p>
    //   //     </>
    //   //   );
    //   // },
    // },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      cellClassName: "cell-padding",
      align: "left",
      flex: 1,
      minWidth: 150,
      sortable: false,

      renderCell: (params: any) => (
        <Chip
          label={params?.value}
          className={params?.value === "enable" ? "enableChip" : "disableChip"}
        />
      ),
    },
    {
      // field: "action",

      // minWidth: 10,
      width: 80,
      sortable: false,
      align: "center",
      cellClassName: "cell-padding",
      headerAlign: "center",
      renderCell: (params: any) => (
        <>
          <img
            src={ellipsis}
            alt="More Options"
            aria-describedby={id}
            onClick={handleClick(params.row.id, params?.row)}
            style={{ cursor: "pointer" }}
          />
          <Popover
            id={id}
            open={open && selectedBotId === params.row.id}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            className="popOver"
          >
            {/* <Typography
              sx={{ p: 1, cursor: "pointer" }}
              // onClick={handleEditClick}
              className="hoverPopover"
            >
              Edit
            </Typography> */}

            <Typography
              sx={{ p: 1, cursor: "pointer" }}
              onClick={() => handleStatus(params?.row)}
              className="hoverPopover"
            >
              {params?.row?.status === "enable" ? "Disable" : "Enable"}
            </Typography>
            <Typography
              sx={{ p: 1, cursor: "pointer" }}
              onClick={() => handleDelete(params?.row)}
              className="hoverPopover"
            >
              Delete
            </Typography>
            {/* <Typography
              sx={{ p: 1, cursor: "pointer" }}
              // onClick={() => handleDelete(params?.row)}
              className="hoverPopover"
            >
              Details
            </Typography> */}
          </Popover>
        </>
      ),
    },
  ];
  const handleCreateBot = () => {
    navigate(Page.CREATEBOT);
  };
  const handleRowClick = (params: any, event: any, detail: any) => {
    navigate(Page.CHAT, {
      state: {
        chatBotId: params?.row?.id,
        chatBotName: params?.row?.name,
        form: false,
      },
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setRowData(data?.chatbots);
      setRowCount(data?.total_chatbots);
    }
  }, [fulfilledTimeStamp]);
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    setPagination({ ...pagination, page: 0 });
  };
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPagination(newPaginationModel);
  };
  return (
    <>
      <Loader
        loader={
          isLoading ||
          isFetching ||
          deleteBotInfo?.isLoading ||
          changeStatusInfo?.isLoading
        }
      />
      <Grid container spacing={3}>
        <Grid size={12}>
          <Card className="botHeadingCard">
            <Grid container>
              <Grid
                size={{ xs: 7, md: 8, lg: 9, xl: 10 }}
                alignContent={"center"}
              >
                <Typography className="botHeading">My Bots</Typography>
              </Grid>
              <Grid size={{ xs: 5, md: 4, lg: 3, xl: 2 }}>
                <Buttons
                  variant="outlined"
                  type="secondary"
                  sx={{ height: "55px" }}
                  text={"Create new bot"}
                  onClick={handleCreateBot}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent={"space-around"}
              marginTop={"15px"}
            >
              <Grid size={5}>
                <SEARCHBAR onChange={handleSearch} value={search} />
              </Grid>
              <Grid size={7}>
                <Grid container spacing={2} justifyContent={"end"}>
                  <Grid size={4}>
                    <SelectInput
                      placeholder={"Status"}
                      optionValue={filterOptionValues}
                      onChange={handleBotFilterChange}
                      value={status}
                    />
                  </Grid>
                  {/* <Grid size={4}>
                    <SelectInput placeholder={"All Status"} optionValue={[]} />
                  </Grid>
                  <Grid size={4}>
                    <SelectInput
                      placeholder={"Date Created"}
                      optionValue={[]}
                    />
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid size={12}>
          <Card className="botHeadingCard">
            <Typography sx={{ marginBottom: "15px" }} className="botHeading">
              Recent Bots
            </Typography>
            <div className="botTable">
              <Table
                rows={rowData}
                columns={columns}
                isPagination={true}
                onRowClick={handleRowClick}
                pagination={pagination}
                onPaginationModelChange={handlePaginationModelChange}
                rowCount={rowCount}
                tableHeight={tableHeight}
              />
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
