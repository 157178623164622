import { Container, Typography, Box } from "@mui/material";
import { LoginHeader } from "../../layout/loginHeader";
import Grid from "@mui/material/Grid2";
import { email } from "../../../assets/images";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import Buttons from "../../common/button";
import { useLocation, useNavigate } from "react-router-dom";
import { handleErrors, maskEmail } from "../../../utils";
import {
  useResendOtpMutation,
  useVerifyOtpMutation,
} from "../../../redux/services/loginApiSlice";
import { Page } from "../../../routes/config";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { Loader } from "../../common/loader";
import dayjs from "dayjs";

export const VerifyOtp = () => {
  const location = useLocation();

  const state = location?.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [isDisabled, setIsDisabled] = useState(true);

  const [verifyOtp, verifyOtpInfo] = useVerifyOtpMutation<any>();
  const [resendOtp, resendOtpInfo] = useResendOtpMutation<any>();

  const handleChange = (newValue: any) => {
    setOtp(newValue);
  };
  useEffect(() => {
    // Start the timer when the component mounts
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1); // Decrement timer by 1 every second
      }, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    } else {
      setIsDisabled(false);
    }
  }, [timer]);
  const handleResend = () => {
    resendOtp({ email: state?.email });
  };

  const handleVerify = () => {
    verifyOtp({ email: state?.email, otp: otp });
  };
  useEffect(() => {
    if (verifyOtpInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "User Registered successfully",
        })
      );
      navigate(Page.LOGIN);
    }
  }, [verifyOtpInfo?.isSuccess]);
  useEffect(() => {
    if (resendOtpInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "OTP sent successfully",
        })
      );
      setTimer(60);
      setIsDisabled(true);
    }
  }, [resendOtpInfo?.isSuccess]);
  useEffect(() => {
    if (verifyOtpInfo?.isError) {
      handleErrors(verifyOtpInfo, dispatch);
    }
  }, [verifyOtpInfo?.isError]);
  useEffect(() => {
    if (resendOtpInfo?.isError) {
      handleErrors(resendOtpInfo, dispatch);
    }
  }, [resendOtpInfo?.isError]);
  return (
    <>
      <Loader loader={resendOtpInfo?.isLoading || verifyOtpInfo?.isLoading} />
      <Grid container sx={{ height: "100vh" }}>
        <Grid size={12}>
          <LoginHeader />
        </Grid>
        <Grid
          size={12}
          sx={{
            overflow: "auto",
            height: "100%",
            paddingBottom: "200px",
          }}
        >
          <Container maxWidth={"xl"}>
            <Grid container spacing={4} sx={{ marginTop: "6rem" }}>
              {/* Left Section */}
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography className="otpHead">Verify Your Email</Typography>
                <Typography className="otpSubHead">
                  An OTP has been sent to your email
                </Typography>
                <Typography className="email">
                  {maskEmail(state?.email)}
                </Typography>
                <Box
                  component="img"
                  src={email}
                  alt="Robot"
                  sx={{
                    width: "520px",
                    height: "520px",
                    display: "flex",
                    justifySelf: "center",
                  }}
                />
              </Grid>

              {/* Right Section */}
              <Grid size={{ xs: 12, md: 6 }}>
                <Grid container justifyContent={"center"}>
                  <Grid size={{ xs: 12, md: 12, lg: 8, xl: 7 }}>
                    <Box
                      sx={{
                        borderRadius: "24px",
                        padding: "47px 31px",
                        textAlign: "center",
                        boxShadow: "0px 4px 29px 0px #00000040",
                      }}
                    >
                      <Typography className="signIn mt-100">
                        Verification
                      </Typography>
                      <Typography className="otpText">Enter OTP</Typography>
                      <MuiOtpInput
                        value={otp}
                        onChange={handleChange}
                        length={6}
                      />
                      <Typography
                        className={isDisabled ? "disableResend" : "resend"}
                        onClick={isDisabled ? () => {} : handleResend}
                      >
                        Resend OTP{" "}
                        <span className="time">
                          ({" "}
                          {timer > 0
                            ? `00:${timer.toString().padStart(2, "0")}`
                            : "00:00"}
                          )
                        </span>
                      </Typography>
                      <Buttons
                        variant="contained"
                        type="primary"
                        sx={{
                          marginTop: "40px",

                          marginBottom: "100px",
                        }}
                        text={"Verify"}
                        onClick={handleVerify}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid size={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // marginTop: "20px",
              padding: "10px 60px",
              position: "absolute",
              bottom: "0px",
              width: "100%",
              backgroundColor: "white",
              boxShadow: "-1px -2px 14px 3px rgba(0, 0, 0, 0.25)",
              // height: "35px",
            }}
          >
            <span className="bookmark">
              © {dayjs().format("YYYY")} Virat.AI
            </span>
            <span className="bookmark">
              About &nbsp;&nbsp; Support &nbsp;&nbsp; Contact Us
            </span>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
