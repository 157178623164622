export enum Page {
  LOGIN = "/",
  OTP = "/verifyotp",
  BOTS = "/bot",
  CREATEBOT = "/bot/create",
  DASHBOARD = "/dashboard",
  AGENTS = "/agents",
  KNOWLEDGEBASES = "/knowledgebases",
  CHAT = "/bot/chat",
}
