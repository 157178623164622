import { Button } from "@mui/material";
import "./style.css";

const Buttons = (props: any) => {
  const {
    variant,
    disabled,
    className,
    type,
    fullWidth,
    text,
    onClick,
    href,
    endIcon,
    startIcon,
    sx,
    buttonType,
    form,
  } = props;
  return (
    <Button
      form={form}
      data-testid="buttons"
      sx={sx}
      className={className ? `${type} ${className}` : `${type}`}
      variant={variant}
      disabled={disabled}
      fullWidth={true}
      onClick={onClick}
      disableFocusRipple={true}
      href={href}
      type={buttonType}
      data-text={text}
    >
      {type === "primary" && text}
    </Button>
  );
};

export default Buttons;
