import { apiRoutes } from "../../config/api";
// import { apiEndPoints } from "../../constants/enum";
import { apiSlice } from "./apiSlice";

export const loginApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder: any) => ({
    loginUser: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.LOGIN}`,
          method: "POST",
          body: data,
        };
      },
    }),
    registerUser: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.REGISTER}`,
          method: "POST",
          body: data,
        };
      },
    }),
    verifyOtp: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.VERIFYOTP}`,
          method: "POST",
          body: data,
        };
      },
    }),
    resendOtp: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.RESENDOTP}`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
} = loginApiSlice;
