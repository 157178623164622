import { Button, Card, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./style.css";
import { Controller } from "react-hook-form";
import { Input } from "../../common/input";
import { SelectInput } from "../../common/selectInput";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { comingSoon } from "../../../assets/images";
import { useGetDataSoucesQuery } from "../../../redux/services/botApiSlice";
import { useEffect, useState } from "react";
import { Loader } from "../../common/loader";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { deleteIcon } from "../../../assets/icons";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const DefineBot = (props: any) => {
  const { control } = props;
  const options = [
    {
      text: "gpt-4o-mini",
      value: "gpt-4o-mini",
    },
  ];
  return (
    <>
      <Grid container display={"flex"} justifyContent={"center"}>
        <Grid size={3}>
          <Grid container>
            <Grid size={12} sx={{ marginBottom: "20px" }}>
              <Typography className="createSubHeading">Define Bot</Typography>
            </Grid>
            <Grid size={12}>
              <Grid container>
                <Grid size={12}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <>
                        <Input
                          {...field}
                          label="Name"
                          //   placeholder={"Email id"}
                          fullWidth
                          required={true}
                          helperText={error?.message ? error?.message : " "}
                          value={field.value}
                          error={Boolean(error)}
                          onChange={field.onChange}
                        />
                      </>
                    )}
                    name="name"
                    control={control}
                    rules={{
                      required: "Please enter bot name",
                    }}
                  />
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <>
                        <Input
                          {...field}
                          label="Description"
                          multiline={true}
                          maxRows={5}
                          minRows={3}
                          //   placeholder={"Email id"}
                          fullWidth
                          // required={true}
                          helperText={error?.message ? error?.message : " "}
                          value={field.value}
                          error={Boolean(error)}
                          onChange={field.onChange}
                        />
                      </>
                    )}
                    name="description"
                    control={control}
                    // rules={{
                    //   required: "Please enter your description",
                    // }}
                  />
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <>
                        <SelectInput
                          {...field}
                          placeholder={"Select AI Model"}
                          fullWidth
                          required={true}
                          helperText={error?.message ? error?.message : " "}
                          value={field.value}
                          error={Boolean(error)}
                          onChange={field.onChange}
                          optionValue={options}
                          label={"Select AI Model"}
                        />
                      </>
                    )}
                    name="model"
                    control={control}
                    rules={{
                      required: "Please select your model",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export const DefineTraining = (props: any) => {
  const { setUploadedFile, uploadedFile } = props;
  const dispatch = useDispatch();

  const [dataSources, setDataSources] = useState<any>([]);
  const { data, isSuccess, isLoading, isError, refetch }: any =
    useGetDataSoucesQuery({});

  useEffect(() => {
    if (isSuccess) {
      setDataSources(data?.message?.data_sources);
    }
  }, [isSuccess]);
  const handleUpload = async (e: any) => {
    if (e.target.files) {
      const files = e.target.files;

      const validFiles = Array.from(files).filter(
        (file: any) => file.type === "application/pdf"
      );

      if (validFiles.length !== files.length) {
        dispatch(
          setAlert({
            open: true,
            alertType: "error",
            message: "Only PDF files are allowed!",
          })
        );
      } else {
        const filesArray = Array.from(files);
        setUploadedFile((prev: any) => [...prev, ...filesArray]);
      }
    }
  };
  const handleDeleteFile = (index: number) => {
    setUploadedFile((prev: any[]) => prev.filter((_, i) => i !== index));
  };
  return (
    <>
      <Loader loader={isLoading} />
      <Grid container display={"flex"} justifyContent={"center"}>
        <Grid size={12} sx={{ marginBottom: "20px" }}>
          <Typography className="createSubHeading">Define Training</Typography>
        </Grid>
        <Grid size={12}>
          <Typography className="uploadText"> Upload File</Typography>
        </Grid>
        <Grid size={12}>
          {uploadedFile.map((x: any, index: number) => {
            return (
              <span style={{ color: "#696969", display: "flex" }}>
                <PictureAsPdfOutlinedIcon />
                {x.name}{" "}
                <img
                  style={{ cursor: "pointer", marginLeft: "20px" }}
                  src={deleteIcon}
                  onClick={() => handleDeleteFile(index)}
                ></img>
              </span>
            );
          })}

          <Button
            component="label"
            fullWidth
            role={undefined}
            variant="contained"
            tabIndex={-1}
            sx={{ background: "#E0E0E0", height: "80px" }}
          >
            <div style={{ display: "grid" }}>
              <FileUploadOutlinedIcon
                sx={{ justifySelf: "center", color: "#4B4D4F" }}
              />
              <span className="fileText">
                {" "}
                Drag and drop your documents/ Click to add files
              </span>
            </div>
            <input
              type="file"
              style={{ display: "none" }}
              onChange={handleUpload}
              multiple
              accept="application/pdf"
            />
          </Button>
        </Grid>
        <Grid size={12}>
          <span className="smallText"> File supported: PDF.</span>
        </Grid>
        <Grid size={12} textAlign={"center"}>
          <span className={"uploadText"}> Cloud Integration</span>
        </Grid>
        <Grid size={12} textAlign={"center"}>
          <span className={"uploadText"}> OR</span>
        </Grid>

        <Grid size={12} marginBottom={2} marginTop={2}>
          <Grid container spacing={2}>
            {dataSources.map((data: any, index: any) => (
              <Grid size={3} zIndex={1}>
                <Card
                  key={index}
                  className="trainingCard"
                  sx={{
                    position: "relative",
                    background: "#fff",
                    overflow: "visible",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: "15px",
                      left: "8px",
                      right: "9px",
                      bottom: "13px",
                      zIndex: -1,
                      background:
                        index % 2 === 0
                          ? "linear-gradient(90deg, #2200ff 20.5%, #d230ff 100%)"
                          : "#00000026",
                      filter: "blur(14px)",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <div className="ribbon">Upcoming</div>
                  {/* <img
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                    }}
                    src={comingSoon}
                  ></img> */}
                  <div style={{ display: "grid" }}>
                    <span style={{ textAlign: "center", marginTop: "12px" }}>
                      <img
                        src={data?.image_url}
                        style={{ width: "45px", height: "45px" }}
                      ></img>
                    </span>
                    <span style={{ color: "#000" }} className="smallCardTitle">
                      {data?.data_source_name}
                    </span>
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export const LaunchOption = (props: any) => {
  const { getValues } = props;
  return (
    <Grid container display={"flex"} justifyContent={"center"}>
      <Grid size={12} sx={{ marginBottom: "20px" }}>
        <Typography className="createSubHeading">Launch Options</Typography>
      </Grid>
      <Grid size={12} marginBottom={2}>
        <Card className="launchCard">
          <Grid container>
            <Grid size={11}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "26.4px",
                  letterSpacing: "-0.03em",
                }}
              >
                Review
              </Typography>
            </Grid>
            <Grid size={1}>
              {/* <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "600",
                  letterSpacing: "-0.03em",
                  color: "#2200FF",
                }}
              >
                Edit
              </Typography> */}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>
              <Grid container marginBottom={2}>
                <Grid size={3}>
                  <Typography className="launchSubHeading">Bot Name</Typography>
                </Grid>
                <Grid size={9}>
                  <Typography className="launchText">
                    {getValues("name")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container marginBottom={2}>
                <Grid size={3}>
                  <Typography className="launchSubHeading">
                    Description
                  </Typography>
                </Grid>
                <Grid size={9}>
                  <Typography className="launchText">
                    {getValues("description")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid size={6}>
              <Grid container marginBottom={2}>
                <Grid size={3}>
                  <Typography className="launchSubHeading">
                    Date Creation
                  </Typography>
                </Grid>
                <Grid size={9}>
                  <Typography className="launchText">
                    {dayjs().format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container marginBottom={2}>
                <Grid size={3}>
                  <Typography className="launchSubHeading">Status</Typography>
                </Grid>
                <Grid size={9}>
                  <Typography className="launchText">Enable</Typography>
                </Grid>
              </Grid>
              {/* <Grid container marginBottom={2}>
                <Grid size={3}>
                  <Typography className="launchSubHeading">
                    Knowledge Base
                  </Typography>
                </Grid>
                <Grid size={9}>
                  <Typography className="launchText">
                    Knowlegde bsse 1
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
