import { useEffect, useState } from "react";
import { Container, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./style.css";
import { robo } from "../../../assets/images";
import Buttons from "../../common/button";
import { Controller, useForm } from "react-hook-form";
import { RegExpressions } from "../../../constants/regExp";
import { Input, PasswordInput } from "../../common/input";
import { useLoginUserMutation } from "../../../redux/services/loginApiSlice";
import { useDispatch } from "react-redux";
import { setAuthInfo, setLogin } from "../../../redux/features/auth/authSlice";
import SignUp from "./signUp";
import { LoginHeader } from "../../layout/loginHeader";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { Loader } from "../../common/loader";
import { handleErrors } from "../../../utils";
import dayjs from "dayjs";

export const SignIn = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signUp, setSignUp] = useState(false);
  const [loginUser, loginUserInfo] = useLoginUserMutation<any>();
  const onSubmit = (data: any) => {
    loginUser(data);
  };
  useEffect(() => {
    if (loginUserInfo?.isSuccess) {
      const { access_token, user_id } = loginUserInfo?.data;
      dispatch(
        setAuthInfo({
          accessToken: access_token,
          userId: user_id,
        })
      );
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Logged in successfully",
        })
      );
      dispatch(setLogin(true));
      navigate(Page.BOTS);
    }
  }, [loginUserInfo?.isSuccess]);
  useEffect(() => {
    if (loginUserInfo?.isError) {
      handleErrors(loginUserInfo, dispatch);
    }
  }, [loginUserInfo?.isError]);

  return (
    <>
      <Loader loader={loginUserInfo?.isLoading} />
      <Grid container sx={{ height: "100vh" }}>
        <Grid size={12}>
          <LoginHeader />
        </Grid>
        <Grid
          size={12}
          sx={{
            overflow: "auto",
            height: "100%",
            paddingBottom: signUp ? "185px" : "50px",
          }}
        >
          <Container maxWidth={"xl"}>
            <Grid container spacing={4} sx={{ marginTop: "6rem" }}>
              {/* Left Section */}
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography className="heading">
                  {signUp ? "Join Virat.AI Today!" : "Welcome to Virat.AI!"}
                </Typography>
                <Typography className="secondaryText">
                  {signUp
                    ? "Build chatbots, analyze enterprise data, and automate workflows with powerful AI tools at your fingertips."
                    : "You are one step away from creating awesome chatbots and automate workflows with powerful AI tools"}
                </Typography>
                <Box
                  component="img"
                  src={robo}
                  alt="Robot"
                  sx={{ width: "80%", marginTop: "2rem" }}
                />
              </Grid>

              {/* Right Section */}
              <Grid size={{ xs: 12, md: 6 }}>
                <Grid container justifyContent={"center"}>
                  <Grid size={7}>
                    {signUp ? (
                      <SignUp signUp={signUp} setSignUp={setSignUp} />
                    ) : (
                      <Box
                        sx={{
                          borderRadius: "24px",
                          padding: "47px 39px",
                          textAlign: "center",
                          boxShadow: "0px 4px 29px 0px #00000040",
                        }}
                      >
                        <Typography className="signIn">Sign In</Typography>
                        {/* Sign-In Form */}
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Controller
                            render={({
                              field: { ref, ...field },
                              fieldState: { invalid, error },
                            }) => (
                              <>
                                <Input
                                  {...field}
                                  label="Email Id"
                                  // placeholder={"Email id"}
                                  fullWidth
                                  required={true}
                                  helperText={
                                    error?.message ? error?.message : " "
                                  }
                                  value={field.value}
                                  error={Boolean(error)}
                                  onChange={field.onChange}
                                />
                              </>
                            )}
                            name="email"
                            control={control}
                            rules={{
                              required: "Please enter your email Id",
                              pattern: {
                                value: RegExpressions?.Email,
                                message: "Please enter valid email",
                              },
                            }}
                          />
                          <Controller
                            render={({
                              field: { ref, ...field },
                              fieldState: { invalid, error },
                            }) => (
                              <>
                                <PasswordInput
                                  {...field}
                                  label="Password"
                                  // placeholder={"Password"}
                                  value={field.value}
                                  error={Boolean(error)}
                                  required={true}
                                  onChange={field.onChange}
                                  helperText={
                                    error?.message ? error?.message : ""
                                  }
                                  variant="standard"
                                  fullWidth
                                />
                              </>
                            )}
                            name="password"
                            control={control}
                            rules={{
                              required: "Please enter your password",
                            }}
                          />
                          {/* <Typography
                        variant="body2"
                        align="right"
                        sx={{ color: "#696969", cursor: "pointer" }}
                      >
                        Forgot Password?
                      </Typography> */}
                          <Buttons
                            variant="contained"
                            type="primary"
                            sx={{
                              marginTop: "20px",
                              height: "45px",
                            }}
                            text={"Sign In"}
                            buttonType={"submit"}
                          ></Buttons>
                        </form>
                        {/* <Divider>OR</Divider>
                <Button
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                  sx={{ color: "gray", borderColor: "gray" }}
                  fullWidth
                >
                  Sign in with Google
                </Button> */}
                        <Typography
                          variant="body2"
                          sx={{ marginTop: "1rem", color: "#696969" }}
                        >
                          New to Virat.AI?{" "}
                          <span
                            onClick={() => setSignUp(true)}
                            style={{ color: "#2200FF", cursor: "pointer" }}
                          >
                            Create new Account
                          </span>
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid size={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "absolute",
              bottom: "0px",
              // marginTop: "20px",
              padding: "10px 60px",
              width: "100%",
              backgroundColor: "white",
              boxShadow: "-1px -2px 14px 3px rgba(0, 0, 0, 0.25)",
            }}
          >
            <span className="bookmark">
              © {dayjs().format("YYYY")} Virat.AI
            </span>
            <span className="bookmark">
              About &nbsp;&nbsp; Support &nbsp;&nbsp; Contact Us
            </span>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
