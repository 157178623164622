import {
  Card,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./style.css";
import { useEffect, useState } from "react";
import { DefineBot, DefineTraining, LaunchOption } from "./botForm";
import Buttons from "../../common/button";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  useCreateBotMutation,
  useTrainBotMutation,
  useUploadFileMutation,
} from "../../../redux/services/botApiSlice";
import { Page } from "../../../routes/config";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { Loader } from "../../common/loader";
import { handleErrors } from "../../../utils";

export const CreateBot = () => {
  const steps = ["Define Bot", "Bot Training", "Launch Options"];
  const CustomConnector = styled(StepConnector)(({ theme }) => ({
    top: "24px",
    left: "calc(-50% + 26px)",
    right: "calc(50% + 28px)",
    [`& .MuiStepConnector-line`]: {
      borderColor: "#d3d3d3",
      borderTopWidth: 0,
      borderStyle: "dashed",
    },
  }));

  // Custom Step Icon Component
  const CustomStepIcon = styled("div")<{
    ownerState: { active?: boolean; completed?: boolean };
  }>(({ theme, ownerState }) => ({
    background: ownerState.completed
      ? "linear-gradient(90deg, #2200FF 20.5%, #D230FF 100%)"
      : ownerState.active
      ? "linear-gradient(90deg, #2200FF 20.5%, #D230FF 100%)"
      : "#d3d3d3",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    borderRadius: "50%",
    fontWeight: "bold",
    fontSize: "16px",
    ...(ownerState.active && {
      boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2)",
    }),
  }));
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const formData = new FormData();
  const [activeStep, setActiveStep] = useState(0);
  const [uploadedFile, setUploadedFile] = useState<any>([]);
  const [chatBotId, setChatBotId] = useState();
  const [chatBotName, setChatBotName] = useState();

  //APIIntegration
  const [createBot, createBotInfo] = useCreateBotMutation<any>();
  const [uploadFile, uploadFileInfo] = useUploadFileMutation<any>();
  const [trainBot, trainBotInfo] = useTrainBotMutation<any>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const StepContent = ({ step }: { step: number }) => {
    switch (step) {
      case 0:
        return <DefineBot control={control} />;
      case 1:
        return (
          <DefineTraining
            setUploadedFile={setUploadedFile}
            uploadedFile={uploadedFile}
          />
        );
      case 2:
        return <LaunchOption getValues={getValues} />;
      default:
        return <Typography>Unknown step</Typography>;
    }
  };
  const handleSave = () => {
    if (activeStep === 2) {
      navigate(-1);
    }
  };
  const handleNext = () => {
    if (activeStep === 1 && uploadedFile?.length === 0) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: "Please upload a file",
        })
      );
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };
  const handlePrev = () => {
    setActiveStep((prev) => prev - 1);
  };
  const onSubmit = (data: any) => {
    if (uploadedFile?.length === 0) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: "Please upload the file",
        })
      );
    } else {
      createBot(data);
    }
  };
  useEffect(() => {
    if (createBotInfo?.isSuccess) {
      setChatBotId(createBotInfo?.data?.chatbot_id);
      setChatBotName(createBotInfo?.data?.name);
      formData.append("chatbot_id", createBotInfo?.data?.chatbot_id);
      if (uploadedFile.length > 0) {
        uploadedFile.forEach((file: File, index: number) => {
          formData.append(`files`, file); // Use an array-like structure
        });
      }

      // formData.append("files", uploadedFile);
      uploadFile(formData);
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Bot created sucessfully",
        })
      );
    }
  }, [createBotInfo?.isSuccess]);
  useEffect(() => {
    if (uploadFileInfo?.isSuccess) {
      trainBot({
        chatbot_id: chatBotId,
      });
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "File uploaded successfully",
        })
      );
    }
  }, [uploadFileInfo?.isSuccess]);
  useEffect(() => {
    if (trainBotInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Bot trained successfully",
        })
      );
      navigate(Page.CHAT, {
        state: {
          chatBotId: chatBotId,
          chatBotName: chatBotName,
          form: true,
        },
      });
    }
  }, [trainBotInfo?.isSuccess]);
  useEffect(() => {
    if (createBotInfo?.isError) {
      handleErrors(createBotInfo, dispatch);
    }
  }, [createBotInfo?.isError]);
  useEffect(() => {
    if (uploadFileInfo?.isError) {
      handleErrors(uploadFileInfo, dispatch);
    }
  }, [uploadFileInfo?.isError]);
  useEffect(() => {
    if (trainBotInfo?.isError) {
      handleErrors(trainBotInfo, dispatch);
    }
  }, [trainBotInfo?.isError]);
  return (
    <>
      <Loader
        loader={
          createBotInfo?.isLoading ||
          uploadFileInfo?.isLoading ||
          trainBotInfo?.isLoading
        }
      />
      <Grid container spacing={3}>
        <Grid size={12}>
          <Card className="botHeadingCard">
            <Grid container>
              <Grid size={4} sx={{ textAlign: "end" }}>
                <Typography className="botHeading">Create Bot</Typography>
              </Grid>
            </Grid>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<CustomConnector />}
              sx={{
                marginBottom: "20px",
                marginTop: "20px",
                width: "50%",
                justifySelf: "center",
              }}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={({ active, completed, icon }) => (
                      <CustomStepIcon
                        sx={{ width: "56px", height: "56px" }}
                        ownerState={{ active, completed }}
                      >
                        {icon}
                      </CustomStepIcon>
                    )}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <form id="createForm" onSubmit={handleSubmit(onSubmit)}>
              <StepContent step={activeStep} />
              <Grid container justifyContent={"center"}>
                <Grid size={4}>
                  <Grid container>
                    {activeStep !== 0 && (
                      <Grid
                        size={activeStep === 2 ? 12 : 6}
                        textAlign={"center"}
                      >
                        <Buttons
                          variant="outlined"
                          type="secondary"
                          sx={{ height: "32px", width: "91px" }}
                          text={"Prev"}
                          onClick={handlePrev}
                        />
                      </Grid>
                    )}
                    {activeStep !== 2 && (
                      <Grid
                        size={activeStep === 0 ? 12 : 6}
                        textAlign={"center"}
                      >
                        <Buttons
                          variant="outlined"
                          type="secondary"
                          sx={{ height: "32px", width: "91px" }}
                          text={"Next"}
                          onClick={handleNext}
                        />
                      </Grid>
                    )}
                    {activeStep === 2 && (
                      <Grid size={12} textAlign={"center"} marginTop={"24px"}>
                        <Buttons
                          form={"createForm"}
                          variant="contained"
                          buttonType="submit"
                          type="primary"
                          sx={{ height: "41px", width: "300px" }}
                          text={activeStep === 2 ? "Launch" : "Save and Exit"}
                          // onClick={handleSave}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
