import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import "./style.css";

export const SelectInput = (props: any) => {
  const {
    required,
    name,
    className,
    error,
    labelClassName,
    label,
    disabled,
    defaultValue,
    placeholder,
    helperText,
    optionValue,
    onChange,
    value,
    multiple,
    inputRef,
    selectInput,
    autoFocus,
  } = props;

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 50,
        // border: "1px solid #8F909A",
      },
      className:
        props?.className === "filterStyle"
          ? "filterMenuStyle"
          : "dropdownMenuStyle",
    },
  };
  //   let sortedOptions = Array.isArray(optionValue) ? optionValue.sort((a: any, b: any) => {
  //     if (a.text < b.text) return -1
  //     return a.text > b.text ? 1 : 0
  //   }) : [];

  return (
    <div>
      <InputLabel
        className={
          labelClassName
            ? `${labelClassName} inputTextLabel inputTextLabelError inputLabel`
            : "inputTextLabel inputTextLabelError inputLabel"
        }
        htmlFor="bootstrap-input"
        required={required}
        error={error}
        disabled={disabled}
      >
        <span>{label}</span>
      </InputLabel>
      <FormControl sx={{ m: 0, width: "100%" }} className="selecetBox">
        <Select
          error={error}
          multiple={multiple}
          displayEmpty
          disabled={disabled}
          defaultValue={""}
          // placeholder={placeholder}
          className={`${className} selectInput`}
          value={value}
          inputRef={inputRef}
          name={name}
          onChange={onChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          autoFocus={autoFocus}
          // renderValue={(selected: any) => {
          //   if (selected.length == 0 || selected=="undefined") {
          //     return <span style={{ color: "#c5c5c4" }}>
          //     {placeholder}
          //   </span>
          //   }
          //   else if (selected.length){
          //     return selected;
          //   }
          //   else{
          //     return ""
          //   }
          // }}
        >
          <MenuItem className="placeholderOption" disabled value="">
            <span style={{ color: "#4F4F4F", fontSize: "12px" }}>
              {placeholder}
            </span>
          </MenuItem>
          {optionValue.length > 0 ? (
            optionValue?.map((option: any) => {
              return (
                <MenuItem
                  key={option?.value}
                  value={option?.value}
                  disabled={option?.disabled}
                >
                  {option?.text}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value="*****" disabled>
              No options available
            </MenuItem>
          )}
        </Select>
        <FormHelperText sx={{ marginLeft: "0px" }} className="helperClass">
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  );
};
export const CheckboxSelectInput = (props: any) => {
  const {
    required,
    error,
    name,
    labelClassName,
    label,
    disabled,
    defaultValue,
    placeholder,
    helperText,
    optionValue,
    onChange,
    value,
    multiple,
    inputRef,
    children,
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 50,
      },
    },
  };
  //   let sortedOptions = Array.isArray(optionValue) ? optionValue.sort((a: any, b: any) => {
  //     if (a.text < b.text) return -1
  //     return a.text > b.text ? 1 : 0
  //   }) : [];

  return (
    <div>
      <FormControl sx={{ m: 0, width: "100%" }}>
        <InputLabel
          shrink
          className={
            labelClassName
              ? `${labelClassName} inputTextLabel inputTextLabelError`
              : "inputTextLabel inputTextLabelError"
          }
          htmlFor="bootstrap-input"
          required={required}
          error={error}
          disabled={disabled}
        >
          {label}
        </InputLabel>
        <Select
          error={error}
          multiple={multiple}
          displayEmpty
          disabled={disabled}
          // placeholder={placeholder}
          className="selectInput"
          value={value}
          name={name}
          inputRef={inputRef}
          onChange={onChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          renderValue={(selected: any) => {
            if (selected?.length == 0) {
              return <span style={{ color: "#c5c5c4" }}>{placeholder}</span>;
            }
            return selected.map((x: any) => x?.text).join(", ");
          }}
        >
          {children}
        </Select>
        <FormHelperText sx={{ marginLeft: "0px" }} className="helperClass">
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  );
};
