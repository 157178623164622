import { CssBaseline, Box, Link, IconButton, Tooltip } from "@mui/material";
import { logo } from "../../assets/images";
import { Outlet, useLocation } from "react-router-dom";
import { Page } from "../../routes/config";
import "./style.css";
import { MessageAlert } from "../common/snackBar";
import PowerSettingsNewRoundedIcon from "@mui/icons-material/PowerSettingsNewRounded";
import { useDispatch } from "react-redux";
import { clearAuthState } from "../../redux/features/auth/authSlice";
import { clearAlertState } from "../../redux/features/alert/alertSlice";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";

export const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [tableHeight, setTableHeight] = useState(0);
  const handleLogOut = () => {
    dispatch(clearAuthState());
    dispatch(clearAlertState());
  };
  const calHeaight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".footer");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 1);
    setTableHeight(tableHeight);
  };
  useEffect(() => {
    calHeaight();
    const resizeObserver = new ResizeObserver(() => {
      calHeaight();
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  return (
    <>
      <Grid container sx={{ height: "100vh" }}>
        <Grid size={12} marginBottom={"15px"} zIndex={99}>
          <CssBaseline />
          {/* Navbar */}
          <Box
            component="nav"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1rem 2rem",
              boxShadow: " 0px 9px 18px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <img src={logo}></img>
            <MessageAlert></MessageAlert>
            <Box>
              <Link
                href={Page.DASHBOARD}
                underline="none"
                className={
                  location?.pathname.includes(Page.DASHBOARD)
                    ? "selected"
                    : "notSelected"
                }
              >
                Dashboard
              </Link>
              <Link
                href={Page.BOTS}
                underline="none"
                className={
                  location?.pathname.includes(Page.BOTS)
                    ? "selected"
                    : "notSelected"
                }
              >
                Bots
              </Link>
              <Link
                href={Page.AGENTS}
                underline="none"
                className={
                  location?.pathname.includes(Page.AGENTS)
                    ? "selected"
                    : "notSelected"
                }
              >
                Agents
              </Link>
              <Link
                href={Page.KNOWLEDGEBASES}
                underline="none"
                className={
                  location?.pathname.includes(Page.KNOWLEDGEBASES)
                    ? "selected"
                    : "notSelected"
                }
              >
                Knowledge Bases
              </Link>
              <Tooltip title="Log Out">
                <IconButton onClick={handleLogOut}>
                  <PowerSettingsNewRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid
          size={12}
          className="outlet"
          sx={{
            overflow: "auto",
            height: "100%",
            // background:"#fff"
          }}
        >
          <Outlet />
        </Grid>
        {location?.pathname !== Page.CHAT && (
          <Grid size={12} zIndex={"99"}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // marginTop: "20px",
                padding: "10px 60px",
                position: "absolute",
                bottom: "0px",
                width: "100%",
                backgroundColor: "white",
                boxShadow: "-1px -2px 14px 3px rgba(0, 0, 0, 0.25)",
                // height: "35px",
              }}
            >
              <span className="bookmark">
                © {dayjs().format("YYYY")} Virat.AI
              </span>
              <span className="bookmark">
                About &nbsp;&nbsp; Support &nbsp;&nbsp; Contact Us
              </span>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};
