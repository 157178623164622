import { configureStore, combineReducers, Middleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { apiSlice } from "./services/apiSlice";
import authReducer from "../redux/features/auth/authSlice";
import { AsyncEncryptStorage } from "encrypt-storage";
import { loginApiSlice } from "./services/loginApiSlice";
import alertReducer from "./features/alert/alertSlice"

export const encryptStorage = new AsyncEncryptStorage("secret-key-value", {
  stateManagementUse: true,
});

const persistConfig = {
  key: "root",
  storage: encryptStorage,
  blacklist: ["api", "alert"],
};

export const rootReducers = combineReducers({
  // Add the generated reducer as a specific top-level slice
  [apiSlice.reducerPath]: apiSlice.reducer,
  loginApiSlice: loginApiSlice.reducer,
  auth: authReducer,
  alert:alertReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      apiSlice.middleware as Middleware,
      loginApiSlice.middleware as Middleware
    ),
  devTools: true,
});

setupListeners(store.dispatch);
