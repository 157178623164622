import { apiRoutes } from "../../config/api";
// import { apiEndPoints } from "../../constants/enum";
import { apiSlice } from "./apiSlice";

export const chatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder: any) => ({
    createChat: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.CHAT}`,
          method: "POST",
          body: data,
        };
      },
    }),
    getChatHistory: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.CHATHISTORY}`,
          method: "POST",
          body: data,
        };
      },
    }),
    getChatSession: builder.mutation({
      query: (data: any) => {
        const { search, chatbot_id } = data;
        return {
          url: `${apiRoutes?.CHATSESSION}`,
          method: "POST",
          body: { chatbot_id: chatbot_id },
          params: { searchTerm: search },
        };
      },
    }),
    createChatSession: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.CREATECHATSESSION}`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteChatSession: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.DELETECHATSESSION}`,
          method: "DELETE",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useCreateChatMutation,
  useGetChatHistoryMutation,
  useGetChatSessionMutation,
  useCreateChatSessionMutation,
  useDeleteChatSessionMutation,
} = chatApiSlice;
