import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../config/api";
import { clearAlertState, setAlert } from "../features/alert/alertSlice";
import { clearAuthState } from "../features/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  // credentials: 'include',
  prepareHeaders: (headers, { getState }: any) => {
    // Get auth tokens from auth state
    const {
      auth: { accessToken, userId },
    }: any = getState();

    if (accessToken !== undefined && accessToken !== null) {
      headers.set("Authorization", `Bearer ${accessToken}`);
      // headers.set("Content-Type", "application/json");
    }
    return headers;
  },
});
const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result: any;

  result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 500) {
    api.dispatch(
      setAlert({
        open: true,
        alertType: "error",
        message: "Unexpected error exists, please try after some time",
      })
    );
  }
  if (result?.error?.error === "TypeError: Failed to fetch") {
    api.dispatch(clearAuthState());
    api.dispatch(clearAlertState());
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 1,
});
